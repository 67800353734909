<template>
  <div id="app">
    <!-- Spectrograms -->
    <Spectrogram
        :canvasWidth="canvasWidth"
        :canvasHeight="canvasHeight"
        :canvasAxisHeight="canvasAxisHeight"
        :spectrumData="spectrumData"
        title="Original"
    />
    <Spectrogram
        :canvasWidth="canvasWidth"
        :canvasHeight="canvasHeight"
        :canvasAxisHeight="canvasAxisHeight"
        :spectrumData="kirigamiFilteredData"
        title="Kirigami Filtered"
    />

    <!-- Configuration UI -->
    <div class="config-section">
      <label for="kirigamiLRThreshold">Kirigami LR Threshold (0.0 - 1.0):</label>
      <input
          type="range"
          id="kirigamiLRThreshold"
          v-model="config.kirigamiLRThreshold"
          min="0.0"
          max="1.0"
          step="0.01"
      />
      <span>{{ config.kirigamiLRThreshold }}</span>

      <button @click="toggleAdvancedConfigs">{{ advancedButtonText }}</button>

      <div v-if="showAdvancedConfigs">
        <label for="nStdThresh">nStdThresh:</label>
        <input type="text" id="nStdThresh" v-model="config.nStdThresh" />

        <label for="prop_decrease">prop_decrease:</label>
        <input type="text" id="prop_decrease" v-model="config.prop_decrease" />

        <label for="bgLRThreshold">bgLRThreshold:</label>
        <input type="text" id="bgLRThreshold" v-model="config.bgLRThreshold" />

        <label for="kirigamiLRBias">kirigamiLRBias:</label>
        <input type="text" id="kirigamiLRBias" v-model="config.kirigamiLRBias" />
      </div>
    </div>
  </div>
</template>

<script>
import FFT from 'fft.js';
import Spectrogram from './components/Spectrogram.vue';

const nGradFreq = 10; // Gradient for frequency smoothing
const nGradTime = 10; // Gradient for time smoothing
// const nStdThresh = 1.2;
// const prop_decrease = 0.8;
// const bgLRThreshold = 0.35;
// const kirigamiLRThreshold = 0.5;
// const kirigamiLRBias = 14.2129


export default {
  components: {
    Spectrogram,
  },
  data() {
    return {
      audioContext: null,
      fftSize: 256,
      stepSize: 128,
      sampleRate: 16000,
      bufferSize: 1024,
      canvasWidth: 0,
      canvasHeight: 150,
      canvasAxisHeight: 230,
      spectrumData: [], // Original spectrum data
      kirigamiFilteredData: [], // Data for Kirigami Filtered spectrogram

      background_stft_mask_data: [], // Background STFT mask data
      lr_background_weights: [1.6993, 1.6723,  1.6848,  1.7001, -0.2186, -1.2087, -1.6512, -1.6600,
        -1.7214, -1.5893, -1.5938, -1.7056, -1.5982, -1.6372, -1.5676, -1.5714,
        -1.6204, -1.6282, -1.5967, -1.6241, -1.5885, -1.6357, -1.6138, -1.6368,
        -1.6622, -1.5702, -1.6746, -1.6531, -1.6463, -1.6267, -1.6511, -1.7334,
        -1.6470, -1.6555, -1.7199, -1.5922, -1.6640, -1.6255, -1.7018, -1.6575,
        -1.7073, -1.6264, -1.7402, -1.5943, -1.7308, -1.6739, -1.7368, -1.6326,
        -1.6982, -1.5751, -1.7304, -1.6128, -1.6468, -1.6098, -1.5682, -1.7012,
        -1.6395, -1.6243, -1.6620, -1.6857, -1.5970, -1.5960, -1.5687, -1.6087,
        -1.6984, -1.6838, -1.5762, -1.5880, -1.6384, -1.6556, -1.7212, -1.5730,
        -1.6327, -1.7469, -1.6704, -1.7440, -1.7158, -1.5812, -1.7252, -1.6823,
        -1.6007, -1.7097, -1.5728, -1.5682, -1.6926, -1.5629, -1.6346, -1.5701,
        -1.6017, -1.5513, -1.6427, -1.5853, -1.6287, -1.6476, -1.4860, -1.6046,
        -1.5246, -1.5688, -1.5221, -1.5150, -1.5668, -1.5004, -1.6098, -1.5136,
        -1.4439, -1.4171, -0.7438, -0.1002,  1.0492,  1.8365,  1.8496,  1.8347,
        1.8513,  0.8034,  0.5238,  0.6184,  0.3248, -0.7136, -1.4792, -1.5369,
        -1.5315, -1.5802, -1.5227, -1.5399, -1.4254, -1.3930, -1.4276, -1.1943,
        -0.9605], // 128-sized weights array
      lr_kirigami_weights: [0, -18.3491, -24.6602, -14.4715, -17.5296,   3.3874, -24.5011,  -4.2216,
        -0.7945,  -8.5586,  -7.8340,  -7.0767,  -8.9072, -11.5833, -15.3044,
        -16.6471, -14.8068, -26.8404, -12.5982,  -7.2282, -23.1984, -12.7363,
        -23.1789, -14.1280, -11.4796, -23.6311, -14.9374, -15.7898, -17.1691,
        -12.3135, -23.7292, -24.2249, -24.0780,  -9.9399, -22.8470, -19.3368,
        -11.3226, -20.0214, -17.6202,  -8.9748,  -3.6355, -18.3848, -14.0666,
        -17.3465, -14.1262, -18.9449,  -6.4914, -23.4995, -22.4900, -19.5635,
        -8.4116, -11.6816,  -9.6116, -13.4342, -14.6288, -18.1980,  -7.4213,
        -17.0431,  -7.4740,  -4.2298,  -9.4200,  -7.7585,  -0.5338, -21.9902,
        -12.3105, -22.7145,  -5.5408, -26.6383,  -8.4157,   1.6940, -17.3090,
        1.2513, -20.0160, -14.2466, -10.2467,  -8.2305, -12.1150,  -0.3400,
        -24.6260,  -9.6405,   6.7941, -12.3277, -17.0060,   1.6472,   0.3194,
        -18.4925,  -9.4279, -16.5883, -14.0136,  -2.0844, -15.7374,   3.9966,
        -31.8900, -30.1117, -22.3978, -38.4171, -35.1770, -31.3902,  -8.6923,
        -14.3356, -18.7452, -36.7600, -11.4925, -31.2890,  -6.0099, -32.9461,
        -10.2115, -31.7707, -12.3614, -28.8304, -41.9509, -41.0379, -40.3715,
        -38.3096, -18.8801, -21.2674, -13.7884, -35.5953, -49.1885, -50.0339,
        -40.8009, -58.8792, -37.8000, -14.5070,  -6.0153,  65.3630,  76.6294,
        76.5972,  76.5928],
      lr_background_bias: 0.3353,
      bg_sample_max_count: 300, // Maximum length of background data
      noise_stft_db: [],
      mean_freq_noise: [],
      std_freq_noise: [],
      noise_thresh: [],

      // Default configuration values
      config: {
        nStdThresh: 1.2,
        prop_decrease: 0.8,
        bgLRThreshold: 0.35,
        kirigamiLRThreshold: 0.5,
        kirigamiLRBias: 14.2129
      },

      // Show/Hide advanced configs
      showAdvancedConfigs: false
    };
  },
  computed: {
    advancedButtonText() {
      return this.showAdvancedConfigs ? 'Hide Advanced Configs' : 'Show Advanced Configs';
    }
  },
  mounted() {
    this.canvasWidth = window.innerWidth * 0.55;
    this.startAudioProcessing();
  },
  methods: {
    toggleAdvancedConfigs() {
      this.showAdvancedConfigs = !this.showAdvancedConfigs;
    },
    async startAudioProcessing() {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: this.sampleRate,
      });

      const source = this.audioContext.createMediaStreamSource(stream);
      const processor = this.audioContext.createScriptProcessor(this.bufferSize, 1, 1);
      source.connect(processor);
      processor.connect(this.audioContext.destination);

      processor.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0);
        this.processAudioData(inputData);
      };
    },
    processAudioData(inputData) {
      const fft = new FFT(this.fftSize);
      const hopSize = this.stepSize;

      for (let i = 0; i < inputData.length - this.fftSize; i += hopSize) {
        const windowedData = inputData.slice(i, i + this.fftSize);
        const spectrumData = this.calculateFFT(windowedData, fft);
        const kirigamiData = this.kirigamiFilter(spectrumData); // Apply Kirigami filter

        this.updateBackgroundSTFTMaskData(spectrumData); // Update background data
        this.spectrumData = spectrumData;
        this.kirigamiFilteredData = kirigamiData;
      }
    },
    convolve(input, kernel) {
      const output = new Array(input.length).fill(0);
      const kernelRadius = Math.floor(kernel.length / 2);

      for (let i = 0; i < input.length; i++) {
        for (let j = 0; j < kernel.length; j++) {
          const kIndex = j - kernelRadius; // Shift kernel index
          const inputIndex = i + kIndex; // Determine the corresponding input index

          // Only add if the input index is within bounds
          if (inputIndex >= 0 && inputIndex < input.length) {
            output[i] += input[inputIndex] * kernel[j];
          }
        }
      }
      return output;
    },
    updateBackgroundSTFTMaskData(spectrumData) {
      // Normalize spectrum data by L1 norm
      const l1Norm = spectrumData.reduce((acc, value) => acc + Math.abs(value), 0);
      const normalizedData = spectrumData.map(value => value / l1Norm);

      // Logistic regression classification
      const weightedSum = normalizedData.reduce((acc, value, index) => acc + value * this.lr_background_weights[index], 0);
      const probability = this.sigmoid(weightedSum + this.lr_background_bias);

      // console.log(probability)
      if (probability < this.config.bgLRThreshold) {
        if (this.background_stft_mask_data.length >= this.bg_sample_max_count) {
          this.background_stft_mask_data.shift(); // Remove oldest entry if max count exceeded
        }
        this.background_stft_mask_data.push(normalizedData); // Add new data

        // Recalibrate and update noise data
        const [noise_stft_db, mean_freq_noise, std_freq_noise, noise_thresh] = this.recalibrateMaskData(this.background_stft_mask_data);
        this.noise_stft_db = noise_stft_db;
        this.mean_freq_noise = mean_freq_noise;
        this.std_freq_noise = std_freq_noise;
        this.noise_thresh = noise_thresh;
      }
    },

    recalibrateMaskData(background_stft_mask_data) {
      const noise_stft_db = this.ampToDb(background_stft_mask_data);
      const mean_freq_noise = noise_stft_db.map(arr => this.mean(arr)); // Mean across each frequency
      const std_freq_noise = noise_stft_db.map(arr => this.std(arr)); // Std across each frequency
      const noise_thresh = mean_freq_noise.map((mean, idx) => mean + std_freq_noise[idx] * this.config.nStdThresh);
      return [noise_stft_db, mean_freq_noise, std_freq_noise, noise_thresh];
    },

    ampToDb(maskData) {
      return maskData.map(arr => arr.map(value => 20 * Math.log10(value))); // Convert amplitude to dB
    },

    mean(arr) {
      return arr.reduce((sum, value) => sum + value, 0) / arr.length; // Calculate mean
    },

    std(arr) {
      const avg = this.mean(arr);
      return Math.sqrt(arr.reduce((sum, value) => sum + Math.pow(value - avg, 2), 0) / arr.length); // Calculate std
    },

    sigmoid(x) {
      return 1 / (1 + Math.exp(-x));
    },

    dbToAmp(dbData) {
      return dbData.map(value => Math.pow(10, value / 20)); // Convert dB back to amplitude
    },

    kirigamiFilter(spectrumData) {
      // Create a smoothing filter for the mask in time and frequency
      const smoothingFilter = this.createSmoothingFilter(nGradFreq, nGradTime);

      // Convert spectrum data to dB
      const sig_stft_db = this.ampToDb([spectrumData])[0]; // Single spectrum data to dB
      const maskGainDb = Math.min(...sig_stft_db);

      // Calculate the threshold for each frequency/time bin
      const dbThresh = new Array(spectrumData.length).fill(this.noise_thresh); // Create a threshold matrix

      // Mask if the signal is above the threshold
      const sigMask = sig_stft_db.map((value, index) => value < this.mean(dbThresh[index]) ? this.config.prop_decrease : 0)

      const maskedSpectrumData = sig_stft_db.map((value, index) => {
        return value * (1 - sigMask[index]) + maskGainDb * sigMask[index];
      });
      const maskedAmp = this.dbToAmp(maskedSpectrumData);

      // // Normalize spectrum data by L1 norm
      const l1Norm = maskedAmp.reduce((acc, value) => acc + Math.abs(value), 0);
      const normalizedData = maskedAmp.map(value => value / l1Norm);

      // Logistic regression classification
      const weightedSum = normalizedData.reduce((acc, value, index) => acc + value * this.lr_kirigami_weights[index], 0);
      const probability = this.sigmoid(weightedSum + this.config.kirigamiLRBias);

      // console.log(probability)

      if (probability > this.config.kirigamiLRThreshold) {
        return Array(128).fill(0);
      }
      return spectrumData;
    },

    calculateFFT(data, fft) {
      const windowedData = data.map((val, idx) => val * this.hannWindow(idx, this.fftSize));
      const out = fft.createComplexArray();
      fft.realTransform(out, windowedData);
      fft.completeSpectrum(out);

      const magnitude = new Array(this.fftSize / 2);
      for (let i = 0; i < this.fftSize / 2; i++) {
        magnitude[i] = Math.sqrt(out[2 * i] ** 2 + out[2 * i + 1] ** 2);
      }
      return magnitude;
    },
    hannWindow(i, N) {
      return 0.5 * (1 - Math.cos((2 * Math.PI * i) / (N - 1)));
    },
    createSmoothingFilter(nGradFreq, nGradTime) {
      // Create the gradient for frequency
      const freqGradient = [
        ...Array.from({ length: nGradFreq }, (_, i) => i / nGradFreq),
        ...Array.from({ length: nGradFreq + 1 }, (_, i) => 1 - i / nGradFreq)
      ];

      // Create the gradient for time
      const timeGradient = [
        ...Array.from({ length: nGradTime }, (_, i) => i / nGradTime),
        ...Array.from({ length: nGradTime + 1 }, (_, i) => 1 - i / nGradTime)
      ];

      // Create the outer product of the two gradients
      const smoothingFilter = freqGradient.map(f => timeGradient.map(t => f * t));

      // Normalize the smoothing filter
      const sum = smoothingFilter.flat().reduce((a, b) => a + b, 0);
      return smoothingFilter.map(row => row.map(value => value / sum));
    },
  },
};
</script>

<style scoped>
#app {
  text-align: center;
  margin-top: 50px;
}
</style>

