<template>
  <div class="spectrogram-container">
    <div class="main-spectrogram">
      <canvas ref="y_axis" :width="axisWidth" :height="canvasHeight + 40" class="y-axis"></canvas>
      <canvas ref="spectrogram" :width="canvasWidth" :height="canvasHeight" class="spectrogram"></canvas>
      <canvas ref="title" :width="axisWidth" :height="canvasHeight" class="title"></canvas>
      <canvas ref="x_axis" :width="canvasWidth" :height="axisHeight" class="x-axis"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canvasWidth: {
      type: Number,
      default: 1024,
    },
    canvasHeight: {
      type: Number,
      default: 200,
    },
    axisHeight: {
      type: Number,
      default: 50,
    },
    axisWidth: {
      type: Number,
      default: 100,
    },
    spectrumData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Audio Spectrogram',
    },
    unitsPerPixel: { // New prop to control the rate of change
      type: Number,
      default: 2, // Each pixel will represent 4 units of spectrum data
    },
  },
  data() {
    return {
      canvasCtx: null,
      xAxesCtx: null,
      yAxesCtx: null,
      colorMap: [],
    };
  },
  watch: {
    spectrumData: {
      handler(newData) {
        this.updateSpectrogram(newData);
      },
      deep: true,
    },
    title() {
      this.drawTitle();  // Redraw title if it changes
    }
  },
  mounted() {
    this.canvasCtx = this.$refs.spectrogram.getContext('2d');
    this.xAxesCtx = this.$refs.x_axis.getContext('2d');
    this.yAxesCtx = this.$refs.y_axis.getContext('2d');
    this.drawAxes(); // Ensure axes are drawn properly
    this.drawTitle();  // Draw the title when mounted
    const brbgColors = [
      [84, 48, 5],
      [140, 81, 10],
      [191, 129, 45], [223, 194, 125],
      [246, 232, 195], [245, 245, 245], [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
    ];

// Linear interpolation between two colors
    const interpolate = (color1, color2, factor) => {
      return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
    };
    const colorMapSize = 10e4;
    for (let value = 0; value <= colorMapSize; value++) {
      const scaledValue = (value / colorMapSize) * (brbgColors.length - 1);
      const index = Math.floor(scaledValue);
      const factor = scaledValue - index;  // Fractional part for interpolation

      const color1 = brbgColors[index];
      const color2 = brbgColors[index + 1] || brbgColors[index];  // Handle out-of-bounds case
      const interpolatedColor = interpolate(color1, color2, factor);

      this.colorMap.push(interpolatedColor);
    }
  },
  methods: {
    updateSpectrogram(spectrumData) {
      const imageData = this.canvasCtx.createImageData(1, this.canvasHeight);

      const getBrbgColor = (value) => {
        const index = Math.floor((value / 255) * (this.colorMap.length - 1));
        return this.colorMap[index] || [0, 0, 0];
      };

      // Move data faster to the left by adjusting units per pixel
      const widthInPixels = Math.floor(this.canvasWidth / this.unitsPerPixel);

      for (let i = 0; i < this.canvasHeight; i++) {
        // Sample spectrum data based on compression factor
        const dataIndex = Math.floor(((this.canvasHeight - i) / this.canvasHeight) * spectrumData.length);
        const value = spectrumData[dataIndex] || 0;
        let intensity = 0;
        if (value > 0) {
          intensity = Math.log(value * 50 + 1.08) * 255;
          const color = getBrbgColor(intensity);
          // Set the color data for each pixel (4 color channels: RGBA)
          imageData.data[i * 4 + 0] = color[0];
          imageData.data[i * 4 + 1] = color[1];
          imageData.data[i * 4 + 2] = color[2];
          imageData.data[i * 4 + 3] = 255;
        }
        else {
          // const color = getBrbgColor(intensity);
          //
          // // Set the color data for each pixel (4 color channels: RGBA)
          // imageData.data[i * 4 + 0] = 255;
          // imageData.data[i * 4 + 1] = 255;
          // imageData.data[i * 4 + 2] = 255;
          // imageData.data[i * 4 + 3] = 255;

          const color = getBrbgColor(0);
          // Set the color data for each pixel (4 color channels: RGBA)
          imageData.data[i * 4 + 0] = color[0];
          imageData.data[i * 4 + 1] = color[1];
          imageData.data[i * 4 + 2] = color[2];
          imageData.data[i * 4 + 3] = 255;
        }
      }

      // Shift the canvas to the left and plot the new data
      this.canvasCtx.drawImage(
          this.$refs.spectrogram,
          this.unitsPerPixel, // Shift by unitsPerPixel
          0,
          this.canvasWidth - this.unitsPerPixel, // Draw within the remaining space
          this.canvasHeight,
          0,
          0,
          this.canvasWidth - this.unitsPerPixel,
          this.canvasHeight
      );

      // Plot new data on the far right
      for (let j = 0; j < this.unitsPerPixel; j++) {
        this.canvasCtx.putImageData(imageData, this.canvasWidth - 1 - j, 0);
      }
    },
    drawAxes() {
      const ctx = this.yAxesCtx;

      ctx.clearRect(0, 0, 100, this.canvasHeight);
      ctx.fillStyle = 'black';
      ctx.strokeStyle = 'black';
      ctx.font = '12px Arial';

      for (let freq = 0; freq <= 8000; freq += 2000) {
        const y = this.canvasHeight - (freq / 8000) * this.canvasHeight;
        ctx.fillText(`${freq} Hz`, 30, y + 39);
        ctx.beginPath();
        ctx.moveTo(90, y + 39);
        ctx.lineTo(100, y + 39);
        ctx.stroke();
      }

      ctx.font = '14px Arial';
      ctx.textAlign = 'center';
      ctx.rotate(-Math.PI / 2);
      ctx.fillText('Frequency (Hz)', -this.canvasHeight / 2 - 30, 10);
      ctx.restore();

      const x_ctx = this.xAxesCtx;
      x_ctx.font = '14px Arial';
      x_ctx.textAlign = 'center';
      x_ctx.fillText('Time', this.canvasWidth / 2, 20);
    },
    drawTitle() {
      const ctx = this.$refs.title.getContext('2d');
      ctx.clearRect(0, 0, 100, this.canvasHeight);
      ctx.save();
      ctx.font = '15px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';

      // Rotate and draw vertical text
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(this.title, -100, 20);
      ctx.restore();
    }
  },
};
</script>

<style scoped>
.spectrogram {
  border: 1px solid black;
  margin-top: -20px;
}
.x-axis {
  margin-left: 100px;
}
.title {
}
</style>
